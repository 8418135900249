<template>
  <div data-cy="asset-project-form">
    <div v-show="errorAction.projectForm" class="alert alert-danger">{{ errorAction.projectForm }}</div>
    <div class="row">
      <div class="col-lg-6 col-xl-5">
        <FormGroup id="name" v-model="form.name" :disabled="disabled" :error="error['name']" label="Name" type="text" @input="$emit('input', form)" />
        <FormGroup
          id="description"
          v-model="form.description"
          :disabled="disabled"
          :error="fieldErrors['description']"
          label="Summary"
          type="textarea"
          @input="$emit('input', form)"
        />
        <FormGroup
          id="status"
          v-model="form.status"
          :disabled="disabled"
          :error="fieldErrors['status']"
          label="Status"
          type="select"
          :options="statusOptions"
          @input="$emit('input', form)"
        />
        <div class="row">
          <div class="col-md-6">
            <FormGroup
              id="startDate"
              v-model="form.startDate"
              :disabled="disabled"
              :error="error['startDate']"
              label="Start Date"
              type="datePicker"
              @input="$emit('input', form)"
            />
          </div>
          <div class="col-md-6">
            <FormGroup
              id="endDate"
              v-model="form.endDate"
              :disabled="disabled"
              :error="fieldErrors['endDate']"
              label="End Date"
              type="datePicker"
              @input="$emit('input', form)"
            />
          </div>
        </div>

        <FormGroup
          id="cost"
          v-model="form.cost"
          :disabled="disabled"
          description="Enter the total cost of this project"
          :error="fieldErrors['cost']"
          label="Cost"
          type="number"
          @input="$emit('input', form)"
        />
        <FormGroup
          v-if="mode === 'create'"
          id="tasks-template"
          label="Tasks Template"
          type="select"
          :options="taskTemplateOptions"
          description="Optionally pre-populate the project tasks using a template"
          @input="onSelectTemplate"
        />
      </div>
      <div class="col-lg-6 col-xl-5 offset-xl-1">
        <FormGroup
          id="accountIds"
          v-model="form.accountIds"
          class="mb-5"
          label="Associated Accounts"
          description="Optionally associate accounts with this project"
          description-position="top"
          placeholder="Add an account..."
          type="select-array-list"
          :options="accountOptions"
          @input="$emit('input', form)"
        />

        <FormGroup
          id="documentIds"
          v-model="form.documentIds"
          label="Associated Documents"
          description="Optionally associate asset documents with this project"
          description-position="top"
          placeholder="Add an document..."
          type="select-array-list"
          :options="documentOptions"
          :hide-no-options-label="form.documents?.length > 0"
          @input="$emit('input', form)"
          @click-item="onClickDocument"
        />
        <div v-if="form.documents?.length > 0" class="MultiSelect">
          <div v-for="document in form.documents" :key="document._id" class="d-flex align-items-center mb-1 MultiSelect-item">
            <button class="btn btn-link text-danger mr-2" @click="removeDocument(document)"><i class="fa fa-times fa-fw"></i></button>
            <div class="flex-grow-1 font-w600 MultiSelect-label" @click.prevent="onClickDocument({ ...document, isLegacy: true })">
              {{ document.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import Form from './Form';
import { capitalize } from '@/lib/helpers';

export default {
  name: 'ProjectForm',
  extends: Form,
  props: {
    projectId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      file: {},
      uploadKey: 0,
      statusOptions: [
        { label: 'Pending', value: 'pending' },
        { label: 'Active', value: 'active' },
        { label: 'Completed', value: 'completed' }
      ],
      templates: [
        {
          name: 'Change of Tenancy',
          tasks: [
            {
              name: 'COT Notification - Electricity'
            },
            {
              name: 'COT Notification - Gas'
            },
            {
              name: 'COT Notification - Water'
            },
            {
              name: 'COT Notification - MOP'
            },
            {
              name: 'Accounts set up on database'
            },
            {
              name: 'Meter reads recorded'
            },
            {
              name: 'COT Log'
            },
            {
              name: '000 Entry'
            },
            {
              name: 'First/Final bills received - Electricity'
            },
            {
              name: 'First/Final bills received - Gas'
            },
            {
              name: 'First/Final bills received - Water'
            }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      accounts: 'asset/accounts',
      errorAction: 'asset/errorAction',
      status: 'asset/projectUpload'
    }),
    taskTemplateOptions() {
      return [{ label: 'None', value: '' }, ...this.templates.map(t => ({ label: t.name, value: t.name }))];
    },
    fieldErrors() {
      if (!this.errorAction.projectForm || !this.errorAction.projectForm.fields) return {};

      return this.errorAction.projectForm.fields;
    },
    accountOptions() {
      const sortedAccounts = [...this.accounts].sort((a, b) => {
        if (a.type < b.type) return -1;
        if (a.type > b.type) return 1;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      return sortedAccounts.map(acc => ({ label: `[${capitalize(acc.type)}] ${acc.name} - ${acc.meterPointNumber}`, value: acc._id }));
    },
    documentOptions() {
      const assetDocuments = this.asset.documents.filter(d => d.s3Key);

      const sortedDocuments = assetDocuments.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      return sortedDocuments.map(doc => ({ label: `${doc.name}.${doc.extension}`, value: doc._id }));
    }
  },
  mounted() {
    this.getAccounts({ id: this.$route.params.id });
  },
  methods: {
    ...mapActions({
      getAccounts: 'asset/accounts',
      downloadDocument: 'asset/downloadDocument',
      downloadProjectAttachment: 'asset/downloadProjectAttachment'
    }),
    onSelectTemplate(templateName) {
      const template = this.templates.find(t => t.name === templateName);

      this.form.tasks = template.tasks;

      this.$emit('input', this.form);
    },
    async removeDocument(doc) {
      this.form.documents = this.form.documents.filter(d => d._id !== doc._id);
      this.form.deletedUploads.push(doc);
    },
    async onClickDocument(item) {
      try {
        const doc = item.isLegacy ? this.form.documents.find(d => d._id === item._id) : this.asset.documents.find(d => d._id === item.value);
        if (!doc) throw new Error('Document not found');

        const link = document.createElement('a');

        link.target = '_blank';

        let downloadUrl;

        if (item.isLegacy) {
          downloadUrl = await this.downloadProjectAttachment({
            id: this.projectId,
            documentId: doc._id,
            name: doc.name
          });
        } else {
          downloadUrl = await this.downloadDocument({
            id: this.asset._id,
            documentId: doc._id,
            name: doc.name
          });
        }

        link.href = downloadUrl;
        link.download = doc.name;

        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        this.$toasted.error(e.errorMsg || e.message || 'Could not open document', { position: 'bottom-center', duration: 3000 });
      }
    }
  }
};
</script>
