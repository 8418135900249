<script>
import FormGroup from '../FormGroup';

export default {
  name: 'Form',
  components: {
    FormGroup
  },
  props: {
    id: {
      type: String,
      default: null,
      required: false
    },
    value: {
      type: Object,
      required: true
    },
    error: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'create'
    }
  },
  data() {
    return {
      idChanged: false,
      form: {}
    };
  },
  watch: {
    id(id) {
      if (id !== this.id) {
        this.idChanged = true;
      }
    },
    value(val) {
      if (!this.idChanged) {
        Object.keys(val).forEach(key => {
          this.$set(this.form, key, val[key]);
        });
      } else {
        this.idChanged = false;
      }
    },
    form(form) {
      if (this.form._id === form._id) {
        this.$emit('input', this.form);
      }
    }
  },
  async mounted() {
    Object.keys(this.value).forEach(key => {
      this.$set(this.form, key, this.value[key]);
    });
  }
};
</script>
